import _ from "lodash"

const state = () => ({
  show_contact_profile_dialog: false,
  has_some_contact_profile_field_updated: false,
  routeNextFn: null,
})

const mutations = {
  setShowContactProfileDialog(state, boolean) {
    state.show_contact_profile_dialog = boolean
  },
  setHasSomeContactProfileFieldUpdated(state, boolean) {
    state.has_some_contact_profile_field_updated = boolean
  },
  setContactProfileRouteNextFn(state, data = null) {
    state.routeNextFn = data
  },
}

export default { state, mutations }
