import _ from "lodash"

const loc_mapping_key = [
  {
    exec_key: "subdistrictName",
    code_key: "subdistrictCode",
    req_key: "sub_district_id",
    input_id: "sub_district",
  },
  {
    exec_key: "districtName",
    code_key: "districtCode",
    req_key: "district_id",
    input_id: "district",
  },
  {
    exec_key: "provinceName",
    code_key: "provinceCode",
    req_key: "province_id",
    input_id: "province",
  },
  {
    exec_key: "postalCode",
    code_key: "postalCode",
    req_key: "postcode",
    input_id: "postcode",
  },
]
const getLocationMappingKey = () => {
  return loc_mapping_key
}
const getUserName = (input_editor = [], placeholder = "") => {
  const first_name =
    _.chain(input_editor).find(["id", "first_name"]).get("value").value() || ""

  const last_name =
    _.chain(input_editor).find(["id", "last_name"]).get("value").value() || ""

  const display_name = `${first_name || ""} ${last_name || ""}`.trim()

  return display_name ? display_name : placeholder
}

const getAddressById = (input_editor = [], id = "") => {
  if (_.isEmpty(input_editor) || _.isEmpty(id)) return

  return _.chain(input_editor).find(["id", id]).get("value").value() || ""
}

const getAddress = (input_editor = [], placeholder = "") => {
  let street = getAddressById(input_editor, "street")
  let sub_district = getAddressById(input_editor, "sub_district")
  let district = getAddressById(input_editor, "district")
  let province = getAddressById(input_editor, "province")
  let postcode = getAddressById(input_editor, "postcode")
  let country = getAddressById(input_editor, "country")

  let execute_data = {}

  if (!_.isEmpty(sub_district)) execute_data["subdistrictName"] = sub_district
  if (!_.isEmpty(district)) execute_data["districtName"] = district
  if (!_.isEmpty(province)) execute_data["provinceName"] = province
  if (!_.isEmpty(postcode)) execute_data["postalCode"] = Number(postcode)

  if (!_.isEmpty(execute_data)) {
    const exec_keys = _.keys(execute_data)
    if (
      exec_keys.length > 1 ||
      (exec_keys.length === 1 && !_.includes(exec_keys, "postcode"))
    ) {
      let loc = $nuxt.$locationService.execute(execute_data)

      if (loc.length === 1) {
        let is_BKK = loc[0].provinceCode === 10
        _.forEach(exec_keys, (key) => {
          let { input_id } = _.find(loc_mapping_key, ["exec_key", key])

          if (input_id === "sub_district") {
            sub_district = `${is_BKK ? "แขวง" : "ตำบล"}${sub_district}`
          } else if (input_id === "district") {
            district = `${is_BKK ? "เขต" : "อำเภอ"}${district}`
          }
        })
      }

      province = province && loc.length >= 1 ? "จังหวัด" + province : province
    }
  }

  const display_location = `${street || ""} ${sub_district || ""} ${
    district || ""
  } ${province || ""} ${String(postcode) || ""} ${country || ""}`.trim()

  return display_location ? display_location : placeholder
}

const getGender = (
  input_editor = {},
  value_key = "value",
  placeholder = ""
) => {
  if (
    _.isEmpty(input_editor) ||
    !_.includes(["value", "prev_value"], value_key)
  )
    return

  const display_gender =
    _.find(
      input_editor.options,
      (option) => option.value === input_editor[value_key]
    ).name || ""

  return display_gender ? display_gender : placeholder
}

const contactProfileHelper = {
  getUserName,
  getAddress,
  getAddressById,
  getGender,
  getLocationMappingKey,
}

export default ({ app }, inject) => {
  inject("contactProfileHelper", contactProfileHelper)
}
